import { oranje } from '@dop/ui-primitives/color/colors';
import { rootColorThemeBGOV } from '@dop/ui-primitives/colorTheme/bgovDefinitions/rootColorThemeBGOV';
import { BGOVColorTheme } from '@dop/ui-primitives/colorTheme/ColorTheme.types';

export const headerFooterColorThemeBGOV: BGOVColorTheme = {
	...rootColorThemeBGOV,
	name: 'Oranje',
	positive: {
		...rootColorThemeBGOV.positive,
		normal: {
			normal: oranje.base,
			hover: oranje[590],
			active: oranje[620],
		},
	},
};

export const providerTypes = {
	rechtsvormen: 'rechtsvormen',
	article: 'article',
	browse: 'browse',
	contactForm: 'contactForm',
	contactOverview: 'contactOverview',
	decisionTree: 'decisionTree',
	detacheren: 'detacheren',
	detacherenOverview: 'detacherenOverview',
	factsandfiguresOverview: 'factsandfiguresOverview',
	factsheet: 'factsheet',
	homepageEn: 'homepageEn',
	comingToNL: 'comingToNL',
	prinsjesdag: 'prinsjesdag',
	sdgArticle: 'sdgArticle',
	search: 'search',
	terugbetalen: 'terugbetalen',
	transaction: 'transaction',
	incoterms: 'incoterms',
	cookiePage: 'cookiePage',
	wasteGuide: 'wasteGuide',
	webinardetail: 'webinardetail',
} as const;
